import { space } from 'styled-system'
import { css } from '@emotion/react'
import styled from '@emotion/styled'

const base = css`
	padding: 15px;
	width: 100%;
	font-size: 13px;
	border: 1px solid #ddd;
	border-radius: 2px;
	background-color: #fcfcfc;
`

export default styled.input`
	${base}
	${space}
`

export const Textarea = styled.textarea`
	${base}
	${space}
	resize: vertical;
	min-height: 200px;
`
