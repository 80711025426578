import React from "react";
import { Link } from "gatsby";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Box, Flex } from "@rebass/grid/emotion";

import Button from "../Button";
import Input, { Textarea } from "../Input";

const Label = styled.div`
  display: inline-block;
  width: 42%;
  text-align: right;
  padding-right: 10px;
`;

const Value = styled.div`
  width: 58%;
  display: inline-block;
`;

const Check = styled.div`
  display: flex;
  margin: 15px 0;
  font-size: 13px;
  input {
    margin-top: 4px;
    margin-right: 10px;
  }
`;

const Contact = (props) => (
  <>
    <form
      name="Kontakt"
      method="post"
      action="/danke/"
      data-netlify
      style={{ margin: 0 }}
    >
      <input type="hidden" name="form-name" value="Kontakt" />
      <Flex mb={2}>
        <Box width={1}>
          <Input type="text" name="name" placeholder="Name" required />
        </Box>
      </Flex>
      <Flex mx={-1} flexWrap="wrap">
        <Box mb={2} width={[1, "calc(50% - 8px)"]} mx={1}>
          <Input type="email" name="email" placeholder="Email" required />
        </Box>
        <Box mb={2} width={[1, "calc(50% - 8px)"]} mx={1}>
          <Input
            type="text"
            name="phone"
            placeholder="Telefonnummer"
            required
          />
        </Box>
      </Flex>
      <Flex>
        <Box width={1}>
          <Textarea name="message" placeholder="Ihre Nachricht" required />
        </Box>
      </Flex>
      <div data-netlify-recaptcha="true"></div>
      <Check>
        <input id="consent" type="checkbox" required />
        <label htmlFor="consent">
          Hiermit akzeptiere ich die{" "}
          <Link to="/datenschutz/">Datenschutzbedingungen</Link>.
        </label>
      </Check>
      <Button
        css={css`
          display: table;
          margin: 30px auto 0;
        `}
        type="submit"
      >
        Senden
      </Button>
    </form>
    <Box mt={4}>
      <hr />
      <Label>Telefon</Label>
      <Value>
        <a href="tel: 036450 44 99 33">036450 / 44 99 33</a>
      </Value>
      <Label>Mobil</Label>
      <Value>
        <a href="tel: 036450 44 99 33">0174 / 32 1 77 33</a>
      </Value>
      <Label>Email</Label>
      <Value>
        <a
          href="mailto:info@immobilien-kranichfeld.de"
          target="_blank"
          rel="noreferrer"
        >
          info@immobilien-kranichfeld.de
        </a>
      </Value>
    </Box>
  </>
);

export default Contact;
